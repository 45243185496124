
import {
    defineComponent, Ref, ref,
    computed, PropType, watch
} from 'vue';
import router, { superBaseRouter } from '@/router';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import {
    SearchKey,
    getMenus, getProjectPageData,
    changePath
} from './util';

export default defineComponent({
    components: {
        listPagination,
        deviceIcon,
        deviceStatus,
        secondaryNav
    },
    props: {
        type: {
            type: String as PropType< ProjectType >,
            default: 'community'
        }
    },
    setup(props) {
        // 新增头部标签community\office
        const secondMenus = getMenus(superBaseRouter);
        const activeNavItem = computed(() => props.type);
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const searchKeyList: Ref<SearchKey> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const projectPageData = getProjectPageData();
        watch(activeNavItem, () => {
            const pageData = projectPageData[activeNavItem.value];
            headers.value = pageData.header;
            searchKeyList.value = pageData.searchKeyList;
            listRequestData.value = pageData.requestData;
        }, {
            immediate: true
        });
        const updateToList = ref(0);

        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push('/superIndex/communityDeviceDetail');
        };
        return {
            headers,
            listRequestData,
            searchKeyList,
            updateToList,
            goToDeviceInfo,
            secondMenus,
            activeNavItem,
            changePath
        };
    }
});
