import {
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import router from '@/router';

type SearchKey = Array<{
    name: string;
    label: string;
}>;

type ProjectPageData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        searchKeyList: SearchKey;
    }
};

const changePath = (key: string, path: string) => {
    router.push(path);
};

// 7.0 新增办公板块
function getMenus(basicRouter: string) {
    return [{
        label: WordList.ProperAllCommunity,
        key: 'community',
        path: `/${basicRouter}/projectDevice?type=community`
    }, {
        label: WordList.ProperAllOffice,
        key: 'office',
        path: `/${basicRouter}/projectDevice?type=office`
    }];
}

const getProjectPageData = () => {
    const communityHeaders: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Project',
        label: WordList.ProperAllCommunity
    }, {
        name: 'Installer',
        label: WordList.NavInHtmlCommunity
    }, {
        name: 'Manage',
        label: WordList.ProperAllTextRoleDistributor
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'IP Address',
        label: WordList.TabelDeviceInHtmlIPAddress
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Last Connected',
        label: WordList.PersonaldeviceListTanleBindingTime
    }];

    const officeHeaders: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Project',
        label: WordList.ProperAllOffice
    }, {
        name: 'Installer',
        label: WordList.ProperAllTextOfficeManager
    }, {
        name: 'Manage',
        label: WordList.ProperAllTextRoleDistributor
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'IP Address',
        label: WordList.TabelDeviceInHtmlIPAddress
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Last Connected',
        label: WordList.PersonaldeviceListTanleBindingTime
    }];

    const communityRequestData: RequestData = {
        url: 'getalldevice',
        param: {
            searchKey: 'MAC',
            searchValue: '',
            type: 0
        }
    };
    const officeRequestData: RequestData = {
        url: 'getalldevice',
        param: {
            searchKey: 'MAC',
            searchValue: '',
            type: 1
        }
    };

    const communitySearchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.ProperAllCommunity,
        name: 'Project'
    }, {
        label: WordList.NavInHtmlCommunity,
        name: 'Installer'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }, {
        label: WordList.ProperAllTextRoleDistributor,
        name: 'Manage'
    }];

    const officeSearchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.ProperAllOffice,
        name: 'Project'
    }, {
        label: WordList.ProperAllTextOfficeManager,
        name: 'Installer'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'SipAccount'
    }, {
        label: WordList.ProperAllTextRoleDistributor,
        name: 'Manage'
    }];
    const pageData: ProjectPageData = {
        community: {
            header: communityHeaders,
            requestData: communityRequestData,
            searchKeyList: communitySearchKeyList
        },
        office: {
            header: officeHeaders,
            requestData: officeRequestData,
            searchKeyList: officeSearchKeyList
        }
    };
    return pageData;
};

export default null;
export {
    SearchKey,
    getMenus,
    getProjectPageData,
    changePath
};
